import React from 'react'
const NewsBaseURL = "blog"
const CommuntiesBaseURL = "purpose/communities"
const AreaBaseURL = '/area-guides'
const OfficeBaseURL = '/contact'
const CaseStudyBaseURL = 'property-services/new-homes/case-studies'
const BookaValuationURL   = '/property-services/sell/property-valuation'

export {
    NewsBaseURL,
    AreaBaseURL,
    CaseStudyBaseURL,
    OfficeBaseURL,
    BookaValuationURL,
    CommuntiesBaseURL
}